<template>
  <!-- <div class="body256N"> -->
  <div class="modalCuponDescuento">
    <div class="modalCuponDescuentoCard">
      <div class="modalCuponDescuentoCerrar">
        <headerCerrar @cancelar="cerrarModal" colorIcon="rojoOscuro" />
      </div>

      <div class="modalCuponDescuentoCheck">
        <img :src="iconVerificaCuponCheckAzul" alt="" srcset="" />
      </div>

      <div class="modalCuponDescuentoTitle title">
        ¡{{ t("app.cupon_descuento.tit") }}!
      </div>

      <div class="modalCuponDescuentoTextSup">
        {{ t("app.cupon_descuento.text1") }}
      </div>

      <div class="modalCuponDescuentoInput">
        <inputBox
          typeInput="text"
          :placeHolder="t('app.cupon_descuento.cuponDescuento')"
          requerido="*"
          :rut="false"
          :error="errorCuponDescuento"
          @dispatchChangeData="updateCodigo"
          @evaluarData="evaluarData"
          :mensajeError="[
            t('configuracion.data.textos_errores.campoObligatorio'),
            mensajeError,
          ]"
          @blur="visitado = true"
          :mensajeOk="{
            mostrar: false,
            texto: '',
          }"
          dataInicial=""
        />
      </div>

      <div class="modalCuponDescuentoBtn">
        <btnAction
          :textBtn="t('app.comunes.btns.continuar')"
          @action="validarCodigoDescuento"
          colorBack="azul"
          colorText="blco"
          :estadoBtn="estadoBtn"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { modalController } from "@ionic/vue";
import { mapActions, mapMutations, mapState } from "vuex";
import btnAction from "../../components/generales/btnAction.vue";
import headerCerrar from "../../components/generales/headerCerrar.vue";
import inputBox from "../../components/generales/inputBox.vue";

import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cuponDescuento",
  components: {
    btnAction,
    headerCerrar,
    inputBox,
  },
  data() {
    return {
      codigoCupon: "",
      iconVerificaCuponCheckAzul: require("../../assets/imgs/iconVerificaCuponCheckAzul.svg"),
      mensajeError: "",
      errorCuponDescuento: false,
      visitado: false,
    };
  },
  computed: {
    ...mapState(["respCupon"]),

    estadoBtn() {
      let aux = "inactive";
      if (this.codigoCupon != "") {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapActions(["validarCupon"]),
    ...mapMutations(["setshowSpinner"]),

    validarCodigoDescuento() {
      this.setshowSpinner(false);
      this.mensajeError = "";
      if (this.codigoCupon.trim().length == 0) {
        this.errorCuponDescuento = true;
        this.mensajeError = this.t(
          "configuracion.data.textos_errores.campoObligatorio"
        );
        return;
      }
      this.validarCupon(this.codigoCupon.trim());
    },

    cerrarModal() {
      modalController.dismiss({ dismissed: true });
    },
    validarCodigo(resp) {
      modalController.dismiss({ dismissed: true, codigo: resp });
    },
    updateCodigo(data) {
      this.codigoCupon = data;
      this.visitado = true;
    },
    evaluarData(codigoCupon) {
      if (codigoCupon.trim().length == 0) {
        this.errorCuponDescuento = true;
      }
    },
  },
  watch: {
    respCupon() {
      if (this.respCupon.status) {
        this.mensajeError = "";
        if (this.respCupon.resp) {
          if(this.respCupon.obj){
            this.validarCodigo(this.respCupon.obj);
          }else{
            this.errorCuponDescuento = true;
            this.mensajeError = this.respCupon.msg;
          }
          
        } else {
          this.errorCuponDescuento = true;
          if (this.respCupon.msg != "") {
            this.mensajeError = this.respCupon.msg;
          }
        }
      }
    },
    codigoCupon(){
      this.errorCuponDescuento = false;
      this.mensajeError = "";
    }
  },
  mounted() {
    // this.setFocus();
  },
};
</script>

<style >
.ion-page:host {
  background: #e5e5e5 !important;
}
.modalCuponDescuento {
  background-color: #ffffff;
  padding: 0 1.68rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.modalCuponDescuentoCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.6875rem 2.5625rem 3.125rem 2.5625rem;
}

.modalCuponDescuentoCheck {
  margin-top: 1.27rem;
  text-align: center;
}
.modalCuponDescuentoTitle {
  margin-top: 1.5625rem;

  text-align: center;
  color: #614ad3;
}
.modalCuponDescuentoTextSup {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.6875rem;
  line-height: 1.125rem;
  text-align: justify;
  letter-spacing: 0.5px;
  color: #787878;
  margin-top: 1.5625rem;
}
.modalCuponDescuentoInput {
  margin-top: 1.9375rem;
}
.modalCuponDescuentoBtn {
  margin-top: 3.25rem;
  margin-bottom: 3.125rem;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .modalCuponDescuento {
    width: 60%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
<template>
  <div class="inputSim_cortoCont">
    <div class="inputSim_cortoMontoLabel text_basico f_w_500">{{ label }}</div>
    <div class="inputSim_cortoRowCont">
      <div
        class="inputSim_cortoMontoCaja"
        :class="
          stateInput == 'inicio'
            ? id_listaPais == 'desde'
              ? 'inputSim_cortoMontoInicioDesde'
              : 'inputSim_cortoMontoInicio'
            : stateInput == 'onFocus'
            ? 'inputSim_cortoMontoOnFocus'
            : stateInput == 'post'
            ? 'inputSim_cortoMontoPost'
            : stateInput == 'postMandante'
            ? 'inputSim_cortoMontoPostMandante'
            : stateInput == 'onError'
            ? 'inputSim_cortoMontoOnError'
            : ''
        "
      >
        <input
          type="tel"
          class="input"
          :class="modoPesoToDestino ? 'destacado' : 'normal'"
          :id="dynamicID()"
          v-model="valorLocal"
          @keypress="isNumber($event)"
          @keyup="calculateAmountLocal($event)"
          autocomplete=""
          :disabled="!editable"
          ref="focusMe"
          @focus="
            setFocusLocal($event);
            cambiarType($event);
          "
          @blur="cambiarType($event)"
        />
      </div>
      <div class="inputSim_cortoRowContSeparador">
        {{simbolMoney}}
      </div>
    </div>

    <div class="inputSim_cortoMontoErrorCont">{{ msgError }}</div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapState } from 'vuex';

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "inputSim_cortoulador",
  props: {
    modoPesoToDestino: { type: Boolean },
    valor: { type: Number },
    simbolMoney: { type: String },
    tipo: { type: String },
    label: { type: String },
    editable: { type: Boolean },
    cerrarListaPais: { type: Boolean },
    id_listaPais: { type: String },
    otraListaPaisCerrar: { type: String },
    mandante: String,
    error: Boolean,
    msgError: String,
  },
  components: {
    
  },
  data() {
    return {
      valorLocal: "50000",
      delay: null,
      stateInput: "inicio",
      inFocus: false,
    };
  },
  computed: {
    ...mapState(["i18n_locales", "transaccion"]),
  },
  methods: {
    
    cerrarOtraListaPaisLocal(origen) {
      this.$emit("cerrarOtraListaPais", origen);
    },

    calculateAmountLocal(e) {
      var evt = e || window.event;
      if (evt.keyCode === 9) {
        evt.preventDefault();
        return;
      }

      if (this.delay) {
        clearTimeout(this.delay);
      }
     
      this.delay = setTimeout(() => {
        
        let cantidad = this.valorLocal ;
        
        this.$emit("calculateAmountL", {
          tipo: this.tipo,
          valor: cantidad,
          event: e
        });
      }, 700);
    },
    dynamicID() {
      let id = new Date().getTime();
      return id;
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
       if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        // charCode != this.i18n_locales[this.locale].miles_cod &&
        charCode != this.i18n_locales[this.locale].deci_cod
      ) {
        evt.preventDefault();
      } else if (
        charCode == this.i18n_locales[this.locale].deci_cod &&
        this.transaccion.moneda_destino_id != "2"
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
    setFocusLocal(e) {
      var evt = e || window.event;
      if (evt.keyCode === 9 || typeof evt.keyCode == "undefined") {
        evt.preventDefault();
        return;
      }
      this.$emit("setFocus", this.id_listaPais);
    },

    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
        this.$emit("changeInputSimuladorMandante", this.id_listaPais);
      } else if (evento == "blur") {
        this.inFocus = false;
        if (this.error) {
          this.stateInput = "onError";
        } else {
          if (this.mandante == this.id_listaPais) {
            this.stateInput = "postMandante";
          } else {
            this.stateInput = "post";
          }
        }
      }

      this.interactuado = true;
    },
  },
  watch: {
    valor() {
      this.valorLocal = this.valor;
    },
    mandante() {
      if (this.mandante != this.id_listaPais) {
        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }
      }
    },
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.mandante == this.id_listaPais) {
          this.stateInput = "postMandante";
        } else {
          this.stateInput = "post";
        }
      }
    },
  },
  mounted() {
    this.valorLocal = this.valor;
  },
};
</script>

<style>
.inputSim_cortoCont {
  width: 100%;
  
}
.inputSim_cortoMontoLabel {
  display: flex;
  align-items: flex-end;
  color: #787878;
}
.inputSim_cortoRowCont {
  display: flex;
  justify-content: flex-start;
}

.inputSim_cortoMontoCaja {
  width: 65%;

  border-radius: 5px;
  display: flex;
  justify-content: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: 0.5px;

  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
}
.inputSim_cortoMontoInicioDesde {
  border: 1.5px solid #787878;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSim_cortoMontoInicio {
  border: 1px solid #787878;;
  color: #787878;
  background: rgba(225, 225, 225, 0.4);
}
.inputSim_cortoMontoOnFocus {
  border: 2px solid #614ad3;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSim_cortoMontoPost {
  border: 1.5px solid #787878;;
  color: #787878;
  background: rgba(225, 225, 225, 0.4);
}
.inputSim_cortoMontoPostMandante {
  border: 1.5px solid #614ad3;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSim_cortoMontoOnError {
  border: 1.5px solid #787878;
  color: var(--customA-801B1C);
  background-color: #ffffff;
}

.inputSim_cortoMontoCaja .input {
  border: none;
  background-color: #ffffff;
  width: 90%;
}
.inputSim_cortoMontoCaja .input:focus {
  outline: none;
}
.inputSim_cortoRowContSeparador {
  width: fit-content;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  color: #801b1c;
  font-weight: 600;
}


.inputSim_cortoMontoErrorCont {
  height: 10px;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 0.7rem;
  color: #801b1c;
}
</style>